import jQuery from 'jquery';
import FormUtil from './FormUtil';
import 'bootstrap';

function submitPlaceModal(modal) {
    const $buttons = modal.find('button');

    $buttons.prop('disabled', true);

    jQuery.ajax({
        url: '/places/create?layout=0',
        method: 'POST',
        data: jQuery('form[name="create_place"]').serialize(),
        dataType: 'html',
        success: function (data, textStatus, xhr) {
            if (201 === xhr.status) {
                modal.modal('hide');
                const { id, name } = JSON.parse(data);

                const $placeSelect = $('.place-select');

                $placeSelect.append(`<option value="${id}">${name}</option>`);
                $placeSelect.val(id);

                return;
            }

            modal.find('.modal-content').html(data);
            modal.find('form[name="create_place"]').on('submit', function (event) {
                event.preventDefault();
                submitPlaceModal(modal);
            });

            FormUtil.init(modal[0]);

            $buttons.prop('disabled', false);
        }
    });
}

jQuery(document).ready(function () {

    jQuery('#openAddPlaceModal').on('click', function(event) {
        event.preventDefault();
        const modal = jQuery('#helper-modal');

        modal.html('<div class="modal-dialog modal-medium" role="document"><div class="modal-content"><div class="modal-body"><div class="loading"></div></div></div></div>');

        modal.modal('show');

        modal.on('hidden.bs.modal', function () {
            // Clear modal
            modal.html('');
        });

        jQuery.ajax({
            url: '/places/create?layout=0',
            dataType: 'html',
            success: function (text) {
                modal.find('.modal-content').html(text);
                modal.find('form[name="create_place"]').on('submit', function (event) {
                    event.preventDefault();
                    submitPlaceModal(modal);
                });

                FormUtil.init(modal[0]);
            }
        });
    });
});
