import jQuery from 'jquery';
import StringUtil from 'StringUtil';
import FormUtil from 'FormUtil';
import AutocompleteDropdown from 'AutocompleteDropdown';
import moment from 'moment';

import 'query-builder';
import 'query-builder-de';

// Needed for query-builder to recognize the library
window.moment = moment;

/**
 * @param {Group} rule
 * @param {string[]} knownFilterIds
 *
 * @return void
 */
function removeInvalidRules(rule, knownFilterIds) {
    rule.rules = filterInvalidRules(
        rule.rules,
        knownFilterIds,
    );
}

/**
 * @param {array<Group|Rule>} rules
 * @param {string[]} knownFilterIds
 *
 * @return array<Group|Rule>
 */
function filterInvalidRules(rules, knownFilterIds) {
    const isGroupRule = (rule) => !rule.hasOwnProperty('id');
    const isSingularRule = (rule) => !isGroupRule(rule);
    const isKnownRule = (rule) => isSingularRule(rule) && knownFilterIds.includes(rule.id);
    const isEmptyGroupRule = (rule) => isGroupRule(rule) && rule.rules.length === 0;

    return rules
        .filter((rule) => isKnownRule(rule) || isGroupRule(rule))
        .map((rule) => {
            if (isGroupRule(rule)) {
                removeInvalidRules(rule, knownFilterIds);
            }

            return rule;
        })
        .filter((rule) => !isEmptyGroupRule(rule));
}

export default class QueryBuilder {

    static createSelect2Autocomplete(rule, inputName) {
        return '<select name="{{name}}" class="form-control select2 select2-autocomplete select2-no-clear" data-autocomplete-url="{{url}}"></select>'
            .replace('{{name}}', inputName)
            .replace('{{url}}', rule.filter.data.url);
    }

    constructor(properties) {
        this.shortPropsByLongProps = properties.shortcuts;
        this.rulePropsToRemove = properties.shortcuts._remove;
        this.defaultRulePropValues = properties.shortcuts._default;
        this.rulePropsByAlias = properties.shortcuts._duplicates;
        this.filters = properties.filters;
        this.onFirstShow = [];

        this.$container = jQuery(properties.container);
        this.$toggleButton = jQuery(properties.toggleButton);

        this._onSubmit = this._onSubmit.bind(this);
        this._onToggleVisibility = this._onToggleVisibility.bind(this);
        this._beforeDeleteRule = this._beforeDeleteRule.bind(this);
        this._afterUpdateRuleFilter = this._afterUpdateRuleFilter.bind(this);
        this._afterUpdateRuleValue = this._afterUpdateRuleValue.bind(this);
        this._afterCreateRuleFilters = this._afterCreateRuleFilters.bind(this);
        this._afterCreateRuleOperators = this._afterCreateRuleOperators.bind(this);
        this._afterCreateRuleInput = this._afterCreateRuleInput.bind(this);
        this._afterSetDefaultFilter = this._afterSetDefaultFilter.bind(this);
        this._onUpdateSelect2AutocompleteValue = this._onUpdateSelect2AutocompleteValue.bind(this);

        let $form = this.$container.find('form');
        let $submitButton = this.$container.find('.filter-submit');
        let $filterContainer = this.$container.find('.filter-container');

        properties.filters.forEach(function (filter) {
            if ('undefined' === typeof filter.input) {
                return;
            }

            if ('select2-autocomplete' === filter.input) {
                filter.input = QueryBuilder.createSelect2Autocomplete;
            }
        });

        $filterContainer.queryBuilder({
            filters: properties.filters,
            operators: properties.operators,
            lang: {
                operators: properties.operatorNames
            },
            allow_empty: true,
            display_empty_filter: false,
            default_filter: QueryBuilder.DEFAULT_FILTER,
            icons: {
                add_group: 'far fa-plus',
                add_rule: 'far fa-plus',
                remove_group: 'far fa-minus',
                remove_rule: 'far fa-minus',
                error: 'far fa-exclamation-circle'
            }
        });

        this.queryBuilder = $filterContainer[0].queryBuilder;
        this.previouslyShown = false;

        $filterContainer.on('beforeDeleteRule.queryBuilder', this._beforeDeleteRule);
        $filterContainer.on('afterUpdateRuleFilter.queryBuilder', this._afterUpdateRuleFilter);
        $filterContainer.on('afterUpdateRuleValue.queryBuilder', this._afterUpdateRuleValue);
        $filterContainer.on('afterCreateRuleFilters.queryBuilder', this._afterCreateRuleFilters);
        $filterContainer.on('afterCreateRuleOperators.queryBuilder', this._afterCreateRuleOperators);
        $filterContainer.on('afterCreateRuleInput.queryBuilder', this._afterCreateRuleInput);
        $filterContainer.on('getDefaultFilter.filter.queryBuilder', this._afterSetDefaultFilter);

        this.setRootRule(properties.rootRule);

        $form.on('submit', this._onSubmit);
        $submitButton.on('click', this._onSubmit);

        this.$toggleButton.on('click', this._onToggleVisibility);
    }

    show() {
        this.$container.addClass('open');
        this.$toggleButton.addClass('open');

        if (!this.previouslyShown) {
            FormUtil.initPreviouslyHidden(this.$container);

            this.onFirstShow.forEach(function (callback) {
                callback();
            });

            this.onFirstShow = [];
        }

        this.previouslyShown = true;
    }

    hide() {
        this.$container.removeClass('open');
        this.$toggleButton.removeClass('open');
    }

    toggleVisibility() {
        this.isVisible() ? this.hide() : this.show();
    }

    isVisible() {
        return this.$container.hasClass('open');
    }

    /**
     * @param {Group} rule
     */
    setRootRule(rule) {
        if (null === rule) {
            rule = QueryBuilder.DEFAULT_RULE;
        }

        this._removeInvalidFilters(rule);

        this.queryBuilder.setRules(rule);

        this._refreshToggleButtonLabel(rule);
    }

    /**
     * @param {Group} rule
     */
    _removeInvalidFilters(rule) {
        removeInvalidRules(
            rule,
            this.filters.map((filter) => filter.id),
        );
    }

    getRootRule() {
        // getRules() triggers validation :(

        return (function parse(group) {
            let data = {
                condition: group.condition,
                rules: []
            };

            if (group.data) {
                data.data = jQuery.extendext(true, 'replace', {}, group.data);
            }

            group.each(
                function(model) {
                    let value = null;

                    if (model.operator.nb_inputs !== 0) {
                        value = model.value;
                    }

                    let rule = {
                        id: model.filter.id,
                        field: model.filter.field,
                        type: model.filter.type,
                        input: model.filter.input,
                        operator: model.operator.type,
                        value: value
                    };

                    if (model.filter.data || model.data) {
                        rule.data = jQuery.extendext(true, 'replace', {}, model.filter.data, model.data);
                    }

                    data.rules.push(rule);
                },
                function(model) {
                    data.rules.push(parse(model));
                }
            );

            return data;

        }(this.queryBuilder.getModel()));
    }

    submit() {
        if (!this.queryBuilder.validate()) {
            return;
        }

        let rootRule = this.getRootRule();

        rootRule = this._removeUnneededRuleProps(rootRule);
        rootRule = this._shortenRuleProps(rootRule);

        window.location.href = window.location.pathname
            + '?' + jQuery.param({q: rootRule});
    }

    reset() {
        this.setRootRule(QueryBuilder.DEFAULT_RULE);
    }

    _onSubmit() {
        this.submit();

        return false;
    }

    _onToggleVisibility() {
        this.toggleVisibility();

        return false;
    }

    _beforeDeleteRule(event, rule) {
        this._refreshToggleButtonLabel(this.getRootRule());

        if (QueryBuilder.createSelect2Autocomplete === rule.filter.input) {
            // Detach the event listener
            rule.model.off('update.queryBuilder', this._onUpdateSelect2AutocompleteValue);
        }
    }

    _onUpdateSelect2AutocompleteValue(event, rule, field, value, oldValue) {
        // We are only interested in changes to the value (not the filter or
        // the operator)
        if ('value' !== field) {
            return;
        }

        // Ignore empty/null values
        if ('string' !== typeof value || 0 === value.trim().length) {
            return;
        }

        // This is method is only relevant for prepopulating the field with a
        // value using an AJAX call as soon as the filter is opened for the
        // first time
        if (this.isVisible()) {
            return;
        }

        let $select2 = rule.$el.find('.rule-value-container > select.select2');

        // Do the AJAX call to fetch the value
        let fetchRegion = AutocompleteDropdown.fetch($select2, value);

        // Once the filter is opened, select the fetched value
        this.onFirstShow.push(function () {
            fetchRegion.then(function (data) {
                // Mark the loaded region as selected
                $select2.select2('trigger', 'select', {
                    data: data
                });

                // Don't focus the element after the selection
                $select2.select2('trigger', 'blur');
            });
        });
    }

    _afterUpdateRuleFilter(event, rule) {
        this._refreshToggleButtonLabel(this.getRootRule());

        // If the filter uses an auto-complete dropdown, we need to hook into
        // the selection of the value to trigger the AJAX call
        // Remove the event handler when switching to a different filter
        if (QueryBuilder.createSelect2Autocomplete === rule.filter.input) {
            rule.model.on('update.queryBuilder', this._onUpdateSelect2AutocompleteValue);
        } else {
            rule.model.off('update.queryBuilder', this._onUpdateSelect2AutocompleteValue);
        }
    }

    _afterSetDefaultFilter(event, rule) {
        if (!this.isVisible()) {
            return;
        }

        let $select2 = rule.$el.find('.rule-filter-container > select.select2');

        $select2.select2('trigger', 'select', {
            data: { id: QueryBuilder.DEFAULT_FILTER }
        });

        $select2.select2('trigger', 'blur');
    }

    _afterUpdateRuleValue(event, rule) {
        this._refreshToggleButtonLabel(this.getRootRule());
    }

    _afterCreateRuleFilters(event, rule) {
        let $ruleOpContainer = rule.$el.children('.rule-filter-container');

        $ruleOpContainer.find('select').addClass('select2').addClass('select2-no-clear');

        FormUtil.init(rule.$el.children('.rule-filter-container'));
    }

    _afterCreateRuleOperators(event, rule) {
        let $ruleOpContainer = rule.$el.children('.rule-operator-container');
        $ruleOpContainer.find('select').addClass('select2').addClass('select2-no-clear');
        FormUtil.init($ruleOpContainer);
    }

    _afterCreateRuleInput(event, rule) {
        FormUtil.init(rule.$el.children('.rule-value-container'));
    }

    _refreshToggleButtonLabel(rule) {
        let html = '<i class="far fa-filter"></i> Filter';

        if (rule != QueryBuilder.DEFAULT_RULE) {
            let labels = this._getLabelsOfSelectedFields(rule);

            labels = labels.map((label) => StringUtil.truncate(label, 25));

            if (labels.length > 0) {
                html += ': <strong>' + labels.slice(0, 3).join('</strong>, <strong>') + '</strong>';

                if (labels.length > 3) {
                    html += ' und ' + (labels.length - 3) + ' '
                        + (labels.length > 4 ? 'weitere' : 'weiterer');
                }
            }
        }

        this.$toggleButton.html(html);
    }

    /**
     * @param rule
     * @returns Array
     * @private
     */
    _getLabelsOfSelectedFields(rule) {
        // AND/OR
        if ('undefined' !== typeof rule.rules) {
            let labels = [];

            for (let i = 0; i < rule.rules.length; ++i) {
                let newLabels = labels.concat(this._getLabelsOfSelectedFields(rule.rules[i]));

                for (let j = 0; j < newLabels.length; ++j) {
                    if (-1 === labels.indexOf(newLabels[j])) {
                        labels.push(newLabels[j]);
                    }
                }
            }

            return labels;
        }

        // Regular rule
        for (let i = 0; i < this.filters.length; ++i) {
            if (this.filters[i].id === rule.id) {
                return [this.filters[i].label];
            }
        }

        return [];
    }

    _removeUnneededRuleProps(rule) {

        // AND/OR
        // {
        //   condition: "AND"
        //   rules: [ ... ]
        // }

        if ('undefined' !== typeof rule.rules) {
            for (let i = 0; i < rule.rules.length; ++i) {
                rule.rules[i] = this._removeUnneededRuleProps(rule.rules[i]);
            }

            return rule;
        }

        // Regular rule
        // {
        //   field: "nachname"
        //   id: "nachname"
        //   input: "text"
        //   operator: "equal"
        //   type: "string"
        //   value: "<search>"
        // }

        // Remove unneeded properties (e.g. "input")
        for (let i = 0; i < this.rulePropsToRemove.length; ++i) {
            delete rule[this.rulePropsToRemove[i]];
        }

        // Remove properties containing default values
        for (let prop in this.defaultRulePropValues) {
            if (this.defaultRulePropValues.hasOwnProperty(prop)
                && rule.hasOwnProperty(prop)
                && rule[prop] == this.defaultRulePropValues[prop]) {
                delete rule[prop];
            }
        }

        // Remove duplicate properties
        for (let alias in this.rulePropsByAlias) {
            if (this.rulePropsByAlias.hasOwnProperty(alias)) {
                let prop = this.rulePropsByAlias[alias];

                if (rule.hasOwnProperty(prop)
                    && rule.hasOwnProperty(alias)
                    && rule[prop] == rule[alias]) {
                    delete rule[alias];
                }
            }
        }

        return rule;
    }

    _shortenRuleProps(rule) {
        // AND/OR
        if ('undefined' !== typeof rule.rules) {
            for (let i = 0; i < rule.rules.length; ++i) {
                rule.rules[i] = this._shortenRuleProps(rule.rules[i]);
            }
        }

        // AND/OR or regular rule
        for (let longProp in this.shortPropsByLongProps) {
            if (this.shortPropsByLongProps.hasOwnProperty(longProp) && rule.hasOwnProperty(longProp)) {
                rule[this.shortPropsByLongProps[longProp]] = rule[longProp];

                delete rule[longProp];
            }
        }

        return rule;
    }
}

QueryBuilder.DEFAULT_FILTER = 'nachname';

QueryBuilder.DEFAULT_RULE = {
    condition: 'AND',
    rules: [
        {
            id: 'nachname',
            field: 'nachname',
            type: 'string',
            input: 'text',
            operator: 'equal',
            value: '',
        }
    ]
};
