import jQuery from 'jquery';

jQuery(document).ready(function () {
    var $footer = $('footer');
    var $formActionsFixed = $('.form-actions-fixed');
    var $sideMenuToggler = $('.menu-toggler');

    function setFixedFormActionsBar() {
        var scrollPosition = $(window).scrollTop();
        var bottomOfScreen = scrollPosition + $(window).innerHeight();
        var topOfFooter = $footer.offset().top;

        var isFooterInView = bottomOfScreen > topOfFooter;

        if (isFooterInView) {
            var footerHeightVisible = bottomOfScreen - topOfFooter;

            $formActionsFixed.css('bottom', footerHeightVisible);
        } else {
            $formActionsFixed.css('bottom', 0);
        }
    }

    if ($footer && $formActionsFixed) {
        $(window).scroll(setFixedFormActionsBar);
        // initially call it once to style it if no scroll event will take place
        setFixedFormActionsBar();
    }

    if ($sideMenuToggler) {
        $sideMenuToggler.click(() => {
            // Timeout until animation of sidebar is finished
            setTimeout(setFixedFormActionsBar, 400);
        });
    }
});
