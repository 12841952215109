import AutocompleteDropdown from './AutocompleteDropdown';
import _ from 'underscore';

export default class ContactAutocompleteDropdown extends AutocompleteDropdown
{
    constructor(props) {
        super(props);

        this.$container.on('select2:select', event => {
            let $form = this.$container.parents('form');

            $form.find('.contact-salutation')
                .val(event.params.data.salutation)
                .trigger('change');
            $form.find('.contact-first-name').val(event.params.data.firstName);
            $form.find('.contact-last-name').val(event.params.data.lastName);
            $form.find('.contact-email').val(event.params.data.email);
            $form.find('.contact-id').val(event.params.data.id);
        });
    }
}
