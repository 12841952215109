import jQuery from 'jquery';
import FormUtil from 'FormUtil';
import AutocompleteDropdown from 'AutocompleteDropdown';
import moment from 'moment';

// Needed for query-builder to recognize the library
window.moment = moment;

export default class FilterBuilder {

    constructor(properties) {
        this.previouslyShown = false;

        this.$container = jQuery(properties.container);
        this.$toggleButton = jQuery(properties.toggleButton);

        this.$endfilterDateButton = jQuery(properties.filterAddEndDateButton);
        this.$endfilterDateContainer = jQuery(properties.filterAddEndDateContainer);

        this._onSubmit = this._onSubmit.bind(this);
        this._onToggleVisibility = this._onToggleVisibility.bind(this);
        this._onShowEndDate = this._onShowEndDate.bind(this);

        this._triggerPreselectAutocomplete();

        this.$toggleButton.on('click', this._onToggleVisibility);
        this.$endfilterDateButton.on('click', this._onShowEndDate);
    }

    show() {
        this.$container.addClass('open');
        this.$toggleButton.addClass('open');

        if (!this.previouslyShown) {
            FormUtil.initPreviouslyHidden(this.$container);
            this._triggerPreselectAutocomplete();
        }

        this.previouslyShown = true;
    }

    hide() {
        this.$container.removeClass('open');
        this.$toggleButton.removeClass('open');
    }

    toggleVisibility() {
        this.isVisible() ? this.hide() : this.show();
    }

    isVisible() {
        return this.$container.hasClass('open');
    }

    _onSubmit() {
        this.submit();

        return false;
    }

    _onToggleVisibility() {
        this.toggleVisibility();

        return false;
    }

    _onShowEndDate() {
        this.$endfilterDateButton.addClass('d-none');
        this.$endfilterDateContainer.removeClass('d-none');

        return false;
    }

    _triggerPreselectAutocomplete() {
        this.$container.find('select[data-preselect]:visible').each(function () {
            let $select2 = jQuery(this);
            let value = jQuery(this).data('preselect');

            if (value != '') {
                AutocompleteDropdown.fetch($select2, value).then(function (data) {
                    // Mark the loaded region as selected
                    $select2.select2('trigger', 'select', {
                        data: data
                    });

                    // Don't focus the element after the selection
                    $select2.select2('trigger', 'blur');
                });
            }
        });
    }
}
