import jQuery from 'jquery';
import Dropdown from './Dropdown';
import AutocompleteDropdown from './AutocompleteDropdown';
import CollectionForm from './CollectionForm';
import FormRow from './FormRow';
import DatePicker from './DatePicker';
import ContactAutocompleteDropdown from './ContactAutocompleteDropdown';
import bsCustomFileInput from 'bs-custom-file-input';

import AddressForm from './AddressForm';
import 'select2';
import 'bootstrap-switch-button';
import 'jquery.uniform';
import 'footer-sticky-form';
import 'tinymce';

export default class FormUtil {

    static init(container) {
        let $container = jQuery(container);

        FormUtil._initDropdowns($container);
        FormUtil._initAutocompleteDropdowns($container);
        FormUtil._initCustomDropdowns($container);
        FormUtil._initCollections($container);
        FormUtil._initAddressBlocks($container);
        FormUtil._initDatePickers($container);
        FormUtil._initCheckboxes($container);
        FormUtil._initFileInputs();
        FormUtil._initTinyMCE();
    }

    static initPreviouslyHidden(container) {
        let $container = jQuery(container);

        // Initialize what we need to initialize after showing the tab
        // These elements are filtered out using filter(':visible') when the
        // page is first loaded
        FormUtil._initDropdowns($container);
        FormUtil._initAutocompleteDropdowns($container);
        FormUtil._initCustomDropdowns($container);

        // Needed upon showing to calculate the offset of the delete button
        // This could be done on page load if the positioning of the button is
        // solved in a better way
        FormUtil._initCollections($container);
    }

    static _initDropdowns($container) {
        $container.find('select.select2:not(.select2-autocomplete, .select2-custom)').filter(':visible').each(function () {
            new Dropdown({container: this});
        });
    }

    static _initAutocompleteDropdowns($container) {
        $container.find('select.select2.select2-autocomplete:not(.select2-custom)').filter(':visible').each(function () {
            new AutocompleteDropdown({container: this});
        });
    }

    static _initCustomDropdowns($container) {
        $container.find('select.select2.select2-contacts').filter(':visible').each(function () {
            new ContactAutocompleteDropdown({container: this});
        });
    }

    static _initCollections($container) {
        $container.find('.collection-holder').filter(':visible').each(function () {
            new CollectionForm({
                container: this,
                rowType: FormRow,
            });
        });
    }

    static _initAddressBlocks($container) {
        $container.find('.address-block').each(function () {
            if (jQuery(this).parents('.contact-merge-source').length > 0) {
                return;
            }

            new AddressForm({container: this});
        });
    }

    static _initDatePickers($container) {
        $container.find('.datepicker').each(function () {
            new DatePicker({container: this});
        });
    }

    static _initCheckboxes($container) {
        $container.find('input[type=checkbox]:not(.toggle, .md-check, .md-radiobtn, .switch-button, .icheck), input[type=radio]:not(.toggle, .md-check, .md-radiobtn, .star, .make-switch, .icheck)').each(function () {
            var $element = jQuery(this);

            $element.show();
            $element.uniform();
        });
    }

    static _initFileInputs() {
        bsCustomFileInput.init();
    }

    static _initTinyMCE() {
        tinymce.init({
            selector: '.tinymce',
            plugins: 'advlist link lists',
            toolbar: 'bold italic underline | forecolor backcolor | bullist numlist | link',
            menubar: false,
            statusbar: false,
            language: 'de',
            language_url: '/js/tiny-mce-de.js',
            formats: {
                underline: { inline: 'u', exact : true }
            },
            content_style: 'body { font-family: Roboto,"Helvetica Neue",sans-serif } p { margin-top: 0; margin-bottom: 0; }',
        });
    }
}
